import {
  AmplitudeLogLevel,
  AmplitudeLogger,
} from '@sennder/senn-node-analytics-frontend/lib/types'
import { logger } from '@/services/logger/loggers'

const LOG_PREFIX = `[Amplitude logger]`

class AppAmplitudeLogger implements AmplitudeLogger {
  private _level: AmplitudeLogLevel = AmplitudeLogLevel.Warn

  disable() {
    this._level = AmplitudeLogLevel.None
  }
  enable(logLevel: AmplitudeLogLevel) {
    this._level = logLevel
  }
  log(...args: any[]) {
    if (this._level < AmplitudeLogLevel.Verbose) {
      return
    }
    console.log(...args)
  }
  warn(warn: any, ...rest: any[]) {
    if (this._level < AmplitudeLogLevel.Warn) {
      return
    }
    const warnString = String(warn) || 'Unknown warning'
    logger.warn(`${LOG_PREFIX} warning: ${warnString}`, { warn, ...rest })
  }
  error(error: any, ...rest: any[]) {
    if (this._level < AmplitudeLogLevel.Error) {
      return
    }
    const errorMessage = String(error) || 'Unknown error'
    const errorText = errorMessage.toLowerCase()
    // log network errors as warnings
    const isNetworkError =
      errorText.includes('failed to fetch') ||
      errorText.includes('networkerror') ||
      errorText.includes('load failed') ||
      errorText.includes('fetch error')
    if (isNetworkError) {
      logger.warn(`${LOG_PREFIX} warning: ${errorMessage}`, {
        warn: error,
        ...rest,
      })
      return
    }
    logger.error(`${LOG_PREFIX} error: ${errorMessage}`, { error, ...rest })
  }
  debug(...args: any[]) {
    if (this._level < AmplitudeLogLevel.Debug) {
      return
    }
    console.debug(...args)
  }
}

export const amplitudeLogger = new AppAmplitudeLogger()
