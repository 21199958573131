import { IModulesConfig } from '@sennder/senn-node-microfrontend-interfaces'

export const moduleConfiguration: IModulesConfig[] = [
  {
    name: 'Example',
    component: 'example-component',
    devPort: '9000',
    route: '/example',
    layout: 'AppLayoutDefault',
    middlewares: ['isAuthenticated'],
    sidebarIcon: 'custom-icon.svg',
    analyticsContext: {
      module: 'example-component',
      submodule: '',
    },
    logContext: {
      module: 'example-component',
      codeOwners: 'my-team',
    },
  },
]