import Loggers from '@sennder/senn-node-frontend-loggers'
import { ENV, SENTRY_DSN } from '@/common/config'
import { executeSafelyWithLogging } from './errors-handler'
import { logger } from './logger/loggers'

const { Sentry } = Loggers

export function initSentry() {
  executeSafelyWithLogging(() => {
    if (!SENTRY_DSN) {
      throw new Error('SENTRY_DSN not found')
    }

    Sentry.init({
      dsn: SENTRY_DSN,
      environment: ENV,
      debug: ENV === 'dev',
      /* 
      TODO: Get release version from CI at build time
      https://dev.azure.com/sennder/sennder/_workitems/edit/68969
     */
      release: '1.0.0',
    })
  }, 'sentry.initSentry')
}

export function stopSentry() {
  logger.warn(
    '[ts-website-project-sample - sentry] Stopping Sentry is not supported',
    {}
  )
}