import { WatchStopHandle, h } from 'vue'
import { watch, ref } from 'vue'
import { createRandomString, cloneReactiveToRaw } from './utils'
import {
  RouteLocationNormalizedLoaded,
  Router,
  useRoute,
  useRouter,
} from 'vue-router'
import { modules } from './'
import {
  getStateData,
  getStateProviders,
  getStateCallbacks,
  StoreData,
} from '@/store'
import {
  IMicrofrontendData,
  IModulesConfig,
  IMountResult,
  MountFunc,
} from '@sennder/senn-node-microfrontend-interfaces'
import { moduleConfiguration } from '@/modules-configuration'
import router from '@/router'
import { MicrofrontendLogger, logger } from '@/services/logger/loggers'
import { AnalyticsProvider } from '@/services/analyticsProvider'
import { getFeatureFlagForRoute } from './visibility-handlers'

export const generateComponent = async (moduleConfig: IModulesConfig) => {
  let $route: RouteLocationNormalizedLoaded | null = null
  let $router: Router | null = null
  // Id of a DOM element to mount µ-frontend
  const id = `${createRandomString()}-${moduleConfig.component}`
  // Flag to check if µ-frontend is loaded or throw error
  const isLoaded = ref<boolean | null>(null)
  // Shell router synchronization
  const syncParentRouter = async (route: any) => {
    const currentPath = $route?.fullPath
    if (
      (typeof route === 'string' && currentPath === route) ||
      (typeof route === 'object' && currentPath === route.path)
    ) {
      return
    }
    await $router?.push(route)
  }
  const syncChildRouter = ref<((route: any) => void) | null>(null)
  const unmount = ref<(() => void) | null>(null)

  const watchHandles: WatchStopHandle[] = []

  return {
    mounted() {
      const mountComponent = async () => {
        try {
          // Try to connect µ-frontend
          // TODO: You should replace any with specific interface data you are working
          const mountFunction: MountFunc<any> = await modules[
            moduleConfig.component
          ].connector.bootstrap()
          // If connection is established, mount µ-frontend to the DOM element and pass data
          const data = getStateData()

          const analyticsProvider = new AnalyticsProvider(
            moduleConfig.analyticsContext
          )

          const microfrontendData: IMicrofrontendData<StoreData> = {
            data: cloneReactiveToRaw(data),
            callbacks: {
              ...getStateCallbacks(),
              onUnauthorized: () => {
                // TODO: replace with actual logic
                console.log('Should logout the app')
              },
              syncParentRouter,
            },
            providers: {
              ...getStateProviders(),
              logger: new MicrofrontendLogger(moduleConfig.logContext),
              analytics: analyticsProvider,
              segment: analyticsProvider,
            },
          }
          const mountResult: IMountResult<any> = mountFunction(
            `#${id}`,
            microfrontendData
          )

          if (mountResult.syncChildRouter) {
            // @ts-ignore
            syncChildRouter.value = mountResult.syncChildRouter
            watchHandles.push(
              watch(
                $route!,
                (to) => {
                  mountResult.syncChildRouter(to.fullPath)
                },
                {
                  immediate: true,
                }
              )
            )
          }
          if (mountResult.onSharedDataChanged) {
            watchHandles.push(
              watch(
                () => data,
                (data) => {
                  if (mountResult.onSharedDataChanged) {
                    mountResult.onSharedDataChanged(cloneReactiveToRaw(data))
                  }
                },
                { deep: true }
              )
            )
          }
          if (mountResult.unmount) {
            unmount.value = mountResult.unmount
          }
          isLoaded.value = true
        } catch (error) {
          logger.error(`[µ-frontend ${moduleConfig.component} bootstrap error]`, {
            error,
          })
          isLoaded.value = false
        }
      }
      const registerRouteFeatureFlagWatcher = () => {
        if (moduleConfiguration.length === 0) {
          return
        }
        watchHandles.push(
          watch(
            () => getStateData().featureFlags,
            (featureFlags) => {
              if (!$route) {
                return
              }
              const routeFeatureFlag = getFeatureFlagForRoute($route)
              if (routeFeatureFlag && !featureFlags[routeFeatureFlag]) {
                router.push({ path: '/' })
              }
            },
            // immediately execute to check feature flag on direct page load
            { immediate: true }
          )
        )
      }

      mountComponent()
      registerRouteFeatureFlagWatcher()
      console.info(
        `[ts-website-project-sample - Component Generator]: The following module was mounted: ${moduleConfig.component}`
      )
    },
    unmounted() {
      if (unmount.value) {
        unmount.value()
      }
    },
    setup() {
      $router = useRouter()
      $route = useRoute()

      return () =>
        h('div', [
          isLoaded.value === false
            ? h(
                'dsf-banner',
                {
                  'data-test': 'mf-not-loaded',
                  dsf_level: 'urgent',
                  class: 'max-w-max',
                },
                `${moduleConfig.component} - is not available`
              )
            : h('div', { id }),
        ])
    },
  }
}
