import { isProxy, isReactive, toRaw } from 'vue'

export const createRandomString = () =>
  Math.random()
    .toString(36)
    .substring(4)
    .replace(/[^a-zA-Z]+/g, '')

export const convertToKebabCase = (value: string): string => {
  return value
    .replace(/[A-Z]/g, function (match) {
      return '-' + match.toLowerCase()
    })
    .replace(/^-/, '')
}

/**
 * Creates a deep non-reactive copy of object.
 */
export const cloneReactiveToRaw = <T extends Record<string, any>>(data: T) => {
  const rawData = deepToRaw(data)
  return structuredClone(rawData)
}

/**
 * Creates a non-reactive copy of object, including any nested reactive objects.
 * Copies arrays, "object" values (except Date, RegExp). Does not copy other types.
 *
 * Based on: https://github.com/vuejs/core/issues/5303#issuecomment-1543596383
 */
export const deepToRaw = <T extends Record<string, any>>(sourceObj: T): T => {
  const objectIterator = (input: any): any => {
    if (Array.isArray(input)) {
      return input.map((item) => objectIterator(item))
    }
    if (isReactive(input) || isProxy(input)) {
      return objectIterator(toRaw(input))
    }
    if (
      !!input &&
      typeof input === 'object' &&
      !(input instanceof Date || input instanceof RegExp)
    ) {
      return Object.keys(input).reduce((acc, key) => {
        acc[key as keyof typeof acc] = objectIterator(input[key])
        return acc
      }, {} as T)
    }
    return input
  }

  return objectIterator(sourceObj)
}