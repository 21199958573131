import { FULLSTORY_ORGID, isLocalEnv } from '@/common/config'
import { getStateUser } from '@/store'
import { fullstory } from '@sennder/senn-node-analytics-frontend'
import { executeSafelyWithLogging } from './errors-handler'

export function initFullstory() {
  executeSafelyWithLogging(() => {
    if (!FULLSTORY_ORGID) {
      throw new Error('[ts-website-project-sample - Fullstory]: FULLSTORY_ORGID not found')
    }
    fullstory.init({
      orgId: FULLSTORY_ORGID,
      debug: isLocalEnv(),
      // for local and Dev environments Fullstory will not record sessions
      devMode: isLocalEnv(),
    })
  }, 'fullstory.init')
}

export function identifyUserInFullstory() {
  const { id, username, fullName } = getStateUser()

  executeSafelyWithLogging(
    () =>
      fullstory.identifyUser(id.toString(), {
        email: username,
        displayName: fullName,
      }),
    'fullstory.identifyUser'
  )
}

export function anonymizeUserInFullstory() {
  executeSafelyWithLogging(
    () => fullstory.anonymizeUser(),
    'fullstory.anonymizeUser'
  )
}

export function stopFullstory() {
  executeSafelyWithLogging(
    () => fullstory.stopTracking(),
    'fullstory.stopTracking'
  )
}

export function restartFullstory() {
  executeSafelyWithLogging(
    () => fullstory.restartTracking(),
    'fullstory.restartTracking'
  )
}
