import { isLocalEnv } from '@/common/config'
import { logger } from '@/services/logger/loggers'

export function executeSafelyWithLogging<T extends (...args: any[]) => any>(
  callback: T,
  callbackName: string
) {
  try {
    callback()
  } catch (error) {
    logger.error(`[ts-website-project-sample] Error while executing ${callbackName}: ${error}`, {
      error,
    })
  }
}

export default (error: any, message = '') => {
  if (error.notification) {
    console.log(error.notification, 'error', {})
  }
  if (isLocalEnv()) {
    console.log(
      `Check the console log for details. This message won't be displayed in production. ${message}`,
      'error',
      {}
    )
    console.error(error)
  } else {
    logger.error(message || 'Unhandled shell error', { error })
  }
}
