import { isNotAuthenticated } from '@/router/middlewares/middlewares'
import { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: '/login',
    name: 'Login',
    meta: { middlewares: [isNotAuthenticated], analyticsName: 'auth' },
    //component: () => import('./AuthMicrofrontend.vue'),
  } as RouteRecordRaw,
]
