import { RouteMeta, Router, RouteRecordRaw } from 'vue-router'
import { moduleConfiguration } from '@/modules-configuration'
import {
  isAuthenticated,
  isRootAuthenticated,
  Middleware,
  projectMiddlewares,
} from './middlewares/middlewares'
import { registerMiddlewares } from './middlewares/middlewares-registrator'
import { IModulesConfig } from '@sennder/senn-node-microfrontend-interfaces'
import { authRoutes } from '@/modules/auth'
import { analytics } from '@/services/analyticsProvider'
import { isModuleVisible } from '@/modules/visibility-handlers'
import { getStateData } from '@/store'
import { generateComponent } from '@/modules/component-generator'

const addModuleRoute = (module: IModulesConfig, router: Router) => {
  const component = async () => {
    return generateComponent(module)
  }

  const middlewares: Middleware[] = module.middlewares
    ? module.middlewares
        .filter(
          (middlewareName) =>
            !!projectMiddlewares[
              middlewareName as keyof typeof projectMiddlewares
            ]
        )
        .map((middlewareName) => {
          return projectMiddlewares[
            middlewareName as keyof typeof projectMiddlewares
          ]
        })
    : []

  router.addRoute({
    path: module.route,
    name: module.name,
    component,
    meta: {
      layout: module.layout,
      middlewares,
      moduleComponentName: module.component,
      analyticsName: module.analyticsContext.module,
    },
    children: [
      {
        path: ':catchAll(.*)',
        name: module.name,
        meta: {
          middlewares,
          moduleComponentName: module.component,
          analyticsName: module.analyticsContext.module,
        },
        component,
      },
    ],
  })
}

export const registerRoutesAndMiddlewares = (router: Router) => {
  router.addRoute({
    path: '/',
    name: 'Redirect',
    meta: { middlewares: [isRootAuthenticated] },
  } as RouteRecordRaw)

  for (const route of authRoutes) {
    router.addRoute(route)
  }

  router.addRoute({
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../NotFound.vue'),
    meta: {
      middlewares: [isAuthenticated],
    },
  })

  moduleConfiguration.forEach((module: IModulesConfig) => addModuleRoute(module, router))

  registerMiddlewares(router)

  router.beforeResolve((to) => {
    const { name, meta } = to
    setPageTitle(String(name), meta)
    if (meta.analyticsName) {
      analytics.trackPage(meta.analyticsName, {
        module: meta.analyticsName,
      })
    }
  })
}

export const getFirstVisibleModulePath = () => {
  const firstVisibleModule = moduleConfiguration.find((module) =>
    isModuleVisible(module, getStateData())
  )
  return firstVisibleModule?.route || '/not-found'
}

const setPageTitle = (name: string, meta: RouteMeta): void => {
  // TODO: Define the properly rules to name pages
  const pageName = meta.title || name.charAt(0).toUpperCase() + name.slice(1)
  document.title = `${pageName} |  App - Sennder`
}
