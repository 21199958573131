import { getAuth0Instance } from '@sennder/senn-node-auth0-frontend'
import { logger } from '@/services/logger/loggers'
import { clearState } from '@/store/index'
import router from '@/router'
import { anonymizeTracking } from '@/services/tracking'
import { anonymizeUserInLaunchDarkly } from '@/services/launchDarkly'

export type BaseLogoutOptions = {
  callBackend?: boolean
}

const logoutWithAuth0 = async () => {
  const auth0Instance = getAuth0Instance()
  try {
    await auth0Instance.logout({ returnTo: window.location.origin })
  } catch (error) {
    logger.error('[ts-website-project-sample] Cant logout with Auth0 token: ', { error })
  }
}

export const logout = async (
  options: BaseLogoutOptions = { callBackend: false }
) => {
  const auth0Instance = getAuth0Instance()
  if (!auth0Instance) {
    throw Error('[ts-website-project-sample] Auth0 was not initialized')
  }
  const isAuthenticated = await auth0Instance.isAuthenticated()

  if (isAuthenticated) {
    await logoutWithAuth0()
  }
  clearState()
  logger.unsetUserData()
  router.push({ name: 'Login' })

  anonymizeTracking()
  await anonymizeUserInLaunchDarkly()
}
