import { loadModule } from './loader'
import { IMicrofrontendLoader } from '@sennder/senn-node-microfrontend-interfaces'
import { moduleConfiguration } from '@/modules-configuration'
import { LOCAL_MICROFRONTENDS, ENV } from '@/common/config'

// TODO: You should replace any with specific interface data you are working
const generateConnector = (
  component: string,
  devPort: number | string
): IMicrofrontendLoader<any> => {
  const NAMESPACE = component.replace(/[^a-zA-Z]/g, '')
  const REMOTE = `./${NAMESPACE}App`

  const localMicrofrontends = LOCAL_MICROFRONTENDS?.split(',') || []

  const urls = {
    dev: `https://cdn.dev.cloud.sennder.com/npm/${component}/`,
    uat: `https://cdn.uat.cloud.sennder.com/npm/${component}/`,
    prod: `https://cdn.cloud.sennder.com/npm/${component}/`,
  }
  // It handles if local µ-frontends are specified in the .env.dev file
  // It is needed for local development. Otherwise, it uses staging
  const devUrl = `http://localhost:${devPort}/`
  const stgUrl = `https://cdn.dev.cloud.sennder.com/npm/${component}/`
  urls.dev = localMicrofrontends.includes(component) ? devUrl : stgUrl

  const environment = ENV

  const loader: IMicrofrontendLoader<any> = {
    bootstrap: async () => {
      if (loader.mount) return loader.mount
      const { mount } = await loadModule(
        `${urls[environment as keyof typeof urls]}remoteEntry.js`,
        NAMESPACE,
        REMOTE
      )
      loader.mount = mount
      return mount
    },
    mount: null,
  }
  return loader
}

const modules: {
  [key: string]: {
    connector: IMicrofrontendLoader<any>
  }
} = {}

moduleConfiguration.forEach((projectModule) => {
  const connector = generateConnector(
    projectModule.component,
    projectModule.devPort
  )
  modules[projectModule.component] = {
    connector,
  }
})

export { modules }
