import { LDUser } from 'launchdarkly-js-client-sdk'
import { identify } from '@sennder/senn-node-feature-flags-frontend'
import { getStateUser } from '@/store'
import { LAUNCH_DARKLY_STATIC_KEY } from '@/common/config'

export const identifyUserInLaunchDarkly = async () => {
  const user = getStateUser()

  const ldUser: LDUser = {
    key: user.id as string,
    email: user.username,
    // TODO: pass additional user fields
    // firstName: user.firstName,
    // lastName: user.lastName,
    // country: user.timezone,
  }
  await identify(ldUser)
  console.info(
    '[ts-website-project-sample - LaunchDarkly]: User is identified in launch darkly service'
  )
}

export const getLaunchDarklyAnonymousUser = (): LDUser => ({
  key: LAUNCH_DARKLY_STATIC_KEY,
})

export const anonymizeUserInLaunchDarkly = async () => {
  await identify(await getLaunchDarklyAnonymousUser())
  console.info('[ts-website-project-sample - LaunchDarkly]: User is anonymized in LaunchDarkly')
}
