import {
  GetTokenOptions,
  IMicrofrontendData,
  Tenant,
} from '@sennder/senn-node-microfrontend-interfaces'
import { getAuth0Header, getAuth0Token } from '@/services/tokenManager'
import { getFeatureFlags } from '@sennder/senn-node-feature-flags-frontend'
import { reactive, watch } from 'vue'
import { logger } from '@/services/logger/loggers'
import { logout } from '@/store/logoutActions'
import { getTenant } from '@/plugins/tenants'
import errorsHandler from '@/services/errors-handler'
import { analytics } from '@/services/analyticsProvider'
import { monitor } from '@/services/monitor'
export { loadUserData } from './userDataHelper'

const defaultTokenOptions: GetTokenOptions = {
  usePopup: false,
  throwException: false,
}

const getAuthToken = async (options = defaultTokenOptions) => {
  const { usePopup, throwException, ...restOptions } = options
  return getAuth0Token(restOptions, usePopup, throwException)
}

const getAuthHeader = async (options = defaultTokenOptions) => {
  const { usePopup, throwException, ...restOptions } = options
  return getAuth0Header(restOptions, usePopup, throwException)
}

// TODO: You should replace any with specific interface data you are working
export type StoreData = {
  user?: { id: string; username: string; language: string; fullName: string }
  language: string
  tenant: Tenant
  featureFlags: Record<string, boolean | string>
}

const getEmptyData = (): StoreData => {
  return {
    language: 'en',
    tenant: getTenant(),
    featureFlags: getFeatureFlags() || {},
  }
}

const store = reactive({
  state: {
    data: getEmptyData(),
    callbacks: {
      getToken: getAuthHeader,
      getAuthToken,
      getAuthHeader,
      syncParentRouter: () => {},
      segmentTrackEvent: () => {},
      onUnauthorized: async () => {
        return logout()
      },
      onUnhandledError: errorsHandler,
    },
    providers: {
      logger,
      notifications: {
        error: (message: string, options = {}) =>
          console.log(
            message,
            options,
            '[error notification] function is not implemented.'
          ),
        success: (message: string, options = {}) =>
          console.log(
            message,
            options,
            '[success notification] function is not implemented.'
          ),
      },
      segment: analytics,
      analytics,
      monitoring: monitor,
    },
  } satisfies IMicrofrontendData<StoreData>,
})

// watch feature flags changes in senn-node-feature-flags-frontend and update store
watch(
  getFeatureFlags,
  (featureFlags) => {
    store.state.data.featureFlags = featureFlags
  },
  { deep: true }
)

export const getStateData = (): StoreData => {
  return store.state.data
}

export const getStateUser = () => {
  if (!store.state.data.user) {
    throw new Error('[Store] state.data.user is not initialized')
  }
  return store.state.data.user
}

export const getStateProviders = () => {
  return store.state.providers
}

export const getStateCallbacks = () => {
  return store.state.callbacks
}

export const loadState = async () => {
  // TODO: implement state loading logic
  store.state.data = {
    user: {
      id: 'user1',
      username: 'test_user1@email.com',
      language: 'en',
      fullName: 'Test User',
    },
    language: 'en',
    featureFlags: getStateData().featureFlags,
    tenant: getTenant(),
  }

  return true
}

export const setStoreLanguage = async (language: string) => {
  // TODO: also set language in i18n plugin, if you use any
  store.state.data.language = language
}

export const clearState = () => {
  store.state.data = getEmptyData()
}
