import { createApp } from 'vue'
import { initializeVue as initLaunchDarkly } from '@sennder/senn-node-feature-flags-frontend'
import App from './App.vue'
import router from './router'
import { logger } from '@/services/logger/loggers'
import { loadUserData } from '@/store'
import { registerRoutesAndMiddlewares } from './router/routes'
import { startTracking } from './services/tracking'
import { getLaunchDarklyAnonymousUser } from './services/launchDarkly'
import { LAUNCH_DARKLY_KEY } from './common/config'

async function initialize() {
  window.onerror = function (message, url, line, column, error) {
    logger.error('[ts-website-project-sample- global error]', { error })
  }
  // OPTIONAL: Initialize all data and services here that are independent from token or user data

  if (!LAUNCH_DARKLY_KEY) {
    throw new Error('LAUNCH_DARKLY_KEY not found')
  }

  await initLaunchDarkly(
    LAUNCH_DARKLY_KEY,
    await getLaunchDarklyAnonymousUser()
  )

  await loadUserData()
}

async function start() {
  // TODO: if required, check for user cookie consent before starting tracking
  startTracking()

  try {
    await initialize()
  } catch (error) {
    logger.error(`[ts-website-project-sample - bootstrap]: Bootstrap error`, { error })
  } finally {
    const app = createApp(App)
    registerRoutesAndMiddlewares(router)
    app.use(router)
    // OPTIONAL: Register necessary plugins here, like: GTM, i18n
    app.mount('#app')
  }
}

start()
